import { ToastContainer, toast, Slide } from 'react-toastify';
import React, { Component } from 'react';
import CloseIcon from './Styles';

class Toast extends Component {
  state = {};

  render() {
    return (
      <ToastContainer
        className='ddf-toast'
        bodyClassName='ddf-toast-body'
        position='top-center'
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        transition={Slide}
        closeButton={
          <CloseIcon
            onClick={() => {
              toast.dismiss();
            }}
          />
        }
      />
    );
  }
}

export default Toast;

import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import _Drawer from '@material-ui/core/Drawer';
import _List from '@material-ui/core/List';
import _ListItem from '@material-ui/core/ListItem';
import _ListItemIcon from '@material-ui/core/ListItemIcon';
import _ListItemText from '@material-ui/core/ListItemText';
import { NavLink as _NavLink } from 'react-router-dom';

const StyledLink = styled(_NavLink)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const NavLink = (props) => <StyledLink {...props} />;

export const Title = styled.div`
  color: #656565;
  font-family: Lato, Arial;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 16px;
  padding: 20px 20px 0px 20px;
`;

export const Drawer = withStyles({
  paper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 80px)',
    background: '#E9EDEF',
    border: 'none',
  },
})(_Drawer);

export const ListItemText = withStyles({
  root: {
    padding: '0',
    fontFamily: 'Lato, Arial',
    color: '#10384F',
  },
})(_ListItemText);

export const ListItemIcon = withStyles({
  root: {
    marginRight: '16px',
    minWidth: 'auto',
  },
})(_ListItemIcon);

export const ListItem = withStyles({
  button: {
    color: '#20b0c9',
  },
})(_ListItem);

export const List = _List;

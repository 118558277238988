import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import _routes, { PrivateRoute } from '../../routes';
import Header from '../Shared/Header/Header';
import SideMenu from '../Shared/SideMenu/SideMenu';
import Spinner from '../Shared/Spinner/Spinner';
import Toast from '../Shared/Toast/Toast';
import UserSettingContainer from '../UserSettings/UserSettingsContainer';

class Dashboard extends Component {
  state = {};

  // eslint-disable-next-line consistent-return
  getUserConfirmation = (dialogKey, callback) => {
    const dialogTrigger = window[Symbol.for(dialogKey)];

    if (dialogTrigger) {
      return dialogTrigger(callback);
    }
    callback(true);
  };

  render() {
    const routes = [].concat(...Object.keys(_routes).map((itm) => _routes[itm]));
    return (
      <BrowserRouter getUserConfirmation={this.getUserConfirmation}>
        <div>
          <Toast />
          <Header primary username={this.props.username} />
          <div style={{ width: '200px', float: 'left' }}>
            <SideMenu />
          </div>
          <div style={{ width: 'calc(100% - 200px)', float: 'left' }}>
            <Suspense fallback={<Spinner />}>
              <Switch>
                {routes.map((route) => (
                  <PrivateRoute key={route.path} path={route.path} component={route.container} />
                ))}
                <Route
                  path='/settings'
                  render={() => <UserSettingContainer fullName={this.props.username} email={this.props.email} />}
                />
                <Redirect to='/countrymatch' />
              </Switch>
            </Suspense>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

Dashboard.propTypes = {
  username: PropTypes.string,
  email: PropTypes.string.isRequired,
};

Dashboard.defaultProps = {
  username: '',
};

export default Dashboard;

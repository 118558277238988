import { styled } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useAuthContext } from '../../../MCProvider';
import routes from '../../../routes';

import Icons from '../../../assets/img/menuicons';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, NavLink, Title } from './Styles';

const appMode = import.meta.env.MODE;
const hideNavLink = (isWip) => (appMode !== 'dev' && isWip ? 'hide' : '');
const appModeData = appMode === 'prod' ? 'web' : appMode;

const SideMenu = () => {
  const { groups: userGroups } = useAuthContext();
  const filteredRoutes = {};
  Object.keys(routes).forEach((k) =>
    routes[k].forEach((r) => {
      if (userGroups.some((role) => r.groups.includes(role))) {
        filteredRoutes[k] = [...(filteredRoutes[k] || []), r];
      }
    }),
  );
  const intl = useIntl();
  return (
    <Drawer anchor='left' variant='permanent' open>
      <DrawerHead>
        <div>
          <List>
            {Object.keys(filteredRoutes).map((group) => (
              <Fragment key={group}>
                <Title>{group.toUpperCase()}</Title>
                {filteredRoutes[group].map((route) => (
                  <NavLink
                    data-cy={`menu-${route.path.replace('/', '')}`}
                    to={route.path}
                    activeClassName='activeSideMenuLink'
                    key={route.path}
                    className={hideNavLink(route.isWip)}
                  >
                    <ListItem button disabled={route.isWip}>
                      <ListItemIcon>
                        <route.icon />
                      </ListItemIcon>
                      <ListItemText className='longTextEllipsis' disableTypography primary={intl.formatMessage(route.titleRef)} />
                    </ListItem>
                  </NavLink>
                ))}
              </Fragment>
            ))}
            <div style={{ flex: '1' }} />
            <Title>MASTERDATA</Title>
            <a
              href={`https://masterdata.${appModeData}.ddfarming.de/crops`}
              target='_blank'
              rel='noreferrer'
              style={{ 'text-decoration': 'none' }}
            >
              <ListItem button>
                <ListItemIcon>
                  <Icons.Crops />
                </ListItemIcon>
                <ListItemText className='longTextEllipsis' disableTypography primary='Crops' />
              </ListItem>
            </a>
            <a
              href={`https://masterdata.${appModeData}.ddfarming.de/weeds`}
              target='_blank'
              rel='noreferrer'
              style={{ 'text-decoration': 'none' }}
            >
              <ListItem button>
                <ListItemIcon>
                  <Icons.Weeds />
                </ListItemIcon>
                <ListItemText className='longTextEllipsis' disableTypography primary='Weeds' />
              </ListItem>
            </a>

            <a
              href={`https://masterdata.${appModeData}.ddfarming.de/pests`}
              target='_blank'
              rel='noreferrer'
              style={{ 'text-decoration': 'none' }}
            >
              <ListItem button>
                <ListItemIcon>
                  <Icons.Pests />
                </ListItemIcon>
                <ListItemText className='longTextEllipsis' disableTypography primary='Pests' />
              </ListItem>
            </a>

            <a
              href={`https://masterdata.${appModeData}.ddfarming.de/diseases`}
              target='_blank'
              rel='noreferrer'
              style={{ 'text-decoration': 'none' }}
            >
              <ListItem button>
                <ListItemIcon>
                  <Icons.Diseases />
                </ListItemIcon>
                <ListItemText className='longTextEllipsis' disableTypography primary='Diseases' />
              </ListItem>
            </a>

            <a
              href={`https://masterdata.${appModeData}.ddfarming.de/customdata`}
              target='_blank'
              rel='noreferrer'
              style={{ 'text-decoration': 'none' }}
            >
              <ListItem button>
                <ListItemIcon>
                  <Icons.Custom />
                </ListItemIcon>
                <ListItemText className='longTextEllipsis' disableTypography primary='Custom Data' />
              </ListItem>
            </a>
          </List>
        </div>
      </DrawerHead>
    </Drawer>
  );
};

const DrawerHead = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
});

export default SideMenu;

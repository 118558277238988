import { lazy } from 'react';
import { defineMessages } from 'react-intl';
import { Redirect, Route } from 'react-router-dom';
import Icons from './assets/img/menuicons';
import { useAuthContext } from './MCProvider';

const AimmContainer = lazy(() => import('./components/Aimm/AimmContainer'));
const FcAIModelContainer = lazy(() => import('./components/FcAIModel/FcAIModelContainer'));
const EppoMatchContainer = lazy(() => import('./components/EppoMatch/EppoMatchContainer'));
const UseCasesContainer = lazy(() => import('./components/UseCases/UseCasesContainer'));
const TaskManagerContainer = lazy(() => import('./components/TaskManager/TaskManagerContainer'));
const TaskManagerV2Container = lazy(() => import('./components/TaskManagerV2/TaskManagerContainer'));
const MagicScoutContainer = lazy(() => import('./components/MagicScout/MagicScoutContainer'));
const FcUsecaseContainer = lazy(() => import('./components/FcUsecase/FcUsecaseContainer'));
const FcCountryMatch = lazy(() => import('./components/CountryMatch/EppoMatchContainer'));

export const routeMessages = defineMessages({
  login: {
    id: 'route.login',
    defaultMessage: 'Login',
  },
  usecases: {
    id: 'route.usecases',
    defaultMessage: 'UseCases',
  },
  eppomatch: {
    id: 'route.eppomatch',
    defaultMessage: 'EPPOMatch',
  },
  countrymatch: {
    id: 'route.countrymatch',
    defaultMessage: 'CountryMatch',
  },
  crops: {
    id: 'route.crops',
    defaultMessage: 'Crops',
  },
  weeds: {
    id: 'route.weeds',
    defaultMessage: 'Weeds',
  },
  pests: {
    id: 'route.pests',
    defaultMessage: 'Pests',
  },
  diseases: {
    id: 'route.diseases',
    defaultMessage: 'Diseases',
  },
  growthstages: {
    id: 'route.growthstages',
    defaultMessage: 'GrowthStages',
  },
  /* gsranges: {
    id: 'route.gsranges',
    defaultMessage: 'GSRanges',
  }, */
  settings: {
    id: 'route.settings',
    defaultMessage: 'Settings',
  },
  forbidden: {
    id: 'route.forbidden',
    defaultMessage: 'Error 403',
  },
  customdata: {
    id: 'route.customdata',
    defaultMessage: 'Custom Data',
  },
  aimodel: {
    id: 'route.aimodel',
    defaultMessage: 'AI Model',
  },
  fcaimodel: {
    id: 'route.fcaimodel',
    defaultMessage: 'FC AI Model',
  },
  deploy: {
    id: 'route.deploy',
    defaultMessage: 'Deploy',
  },
  taskmanager: {
    id: 'route.taskmanager',
    defaultMessage: 'Task Manager',
  },
  aimm: {
    id: 'route.aimm',
    defaultMessage: 'AI Model Mappings',
  },
  magicscout: {
    id: 'route.magicScout',
    defaultMessage: 'Magic Scout',
  },
  fcusecase: {
    id: 'route.fcusecase',
  },
  fccountrymatch: {
    id: 'route.fccountrymatch',
  },
  fctaskmanager: {
    id: 'route.fctaskmanager',
  },
});

export const MCGroups = {
  Default: 'MissionControl-Default',
  Admin: 'MissionControl-Admin',
  DummyAdmin: 'DummyMissionControlAdmin',
};

const routes = {
  magicscout: [
    {
      icon: Icons.msconfig,
      path: '/magicscout',
      container: MagicScoutContainer,
      titleRef: routeMessages.magicscout,
      groups: [MCGroups.Admin],
    },
  ],
  fieldCatcher: [
    {
      icon: Icons.UseCases,
      path: '/usecases',
      container: UseCasesContainer,
      titleRef: routeMessages.usecases,
      groups: [MCGroups.Admin, MCGroups.DummyAdmin],
    },
    {
      icon: Icons.aimodel,
      path: '/aimodel',
      container: FcAIModelContainer,
      titleRef: routeMessages.aimodel,
      groups: [MCGroups.Admin, MCGroups.DummyAdmin],
    },
    {
      icon: Icons.UseCases,
      path: '/taskmanager',
      container: TaskManagerContainer,
      titleRef: routeMessages.taskmanager,
      groups: [MCGroups.Admin, MCGroups.DummyAdmin, MCGroups.Default],
    },
    {
      icon: Icons.EPPO,
      path: '/countrymatch',
      container: EppoMatchContainer,
      titleRef: routeMessages.countrymatch,
      groups: [MCGroups.Admin, MCGroups.DummyAdmin, MCGroups.Default],
    },
  ],
  fcNew: [
    {
      icon: Icons.UseCases,
      path: '/fcusecase',
      container: FcUsecaseContainer,
      titleRef: routeMessages.fcusecase,
      groups: [MCGroups.Admin, MCGroups.DummyAdmin],
    },
    {
      icon: Icons.EPPO,
      path: '/fccountrymatch',
      container: FcCountryMatch,
      titleRef: routeMessages.fccountrymatch,
      groups: [MCGroups.Admin, MCGroups.DummyAdmin],
    },
    {
      icon: Icons.UseCases,
      path: '/fctaskmanager',
      container: TaskManagerV2Container,
      titleRef: routeMessages.fctaskmanager,
      groups: [MCGroups.Admin, MCGroups.DummyAdmin],
    },
  ],
  aiModels: [
    {
      icon: Icons.aimapping,
      path: '/aimm',
      container: AimmContainer,
      titleRef: routeMessages.aimm,
      groups: [MCGroups.Admin],
    },
  ],
};

export default routes;

/* eslint-disable react/prop-types */
export const PrivateRoute = ({ component, path }) => {
  const { groups: userGroups } = useAuthContext();
  const ret = Object.keys(routes)
    .flatMap((k) => {
      const currentRoute = routes[k].find((r) => r.path === path);
      if (currentRoute) {
        return currentRoute;
      }
      return null;
    })
    .find((e) => e !== null);
  const isValid = userGroups.some((r) => ret.groups.includes(r));
  return isValid ? <Route path={path} component={component} /> : <Redirect to='/countrymatch' />;
};

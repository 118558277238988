import { ApolloClient as _ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';
import { configAmplify } from '../AWSConfig';
import resolvers from './resolvers';

configAmplify();

const authLink = setContext(async () => {
  const sess = await Auth.currentSession();
  return {
    headers: {
      authorization: sess.getIdToken().getJwtToken(),
    },
  };
});

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQLENDPOINT,
});

/**
 * @type {import('@apollo/client').ApolloClient}
 */
const ApolloClient = new _ApolloClient({
  cache: new InMemoryCache(),
  resolvers,
  link: from([authLink, httpLink]),
});

export default ApolloClient;

/* eslint-disable-next-line import/no-unresolved */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import lokalise from './assets/img/Lokalise_icon.svg';

export const EditSvgIcon = ({ fill, width, height }) => (
  <svg width={width} height={height} viewBox='0 0 26 26' version='1.1'>
    <g id='Assets' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-173.000000, -271.000000)' fill={fill} fillRule='nonzero' id='Edit'>
        <g transform='translate(173.000000, 271.000000)'>
          <rect id='Rectangle' fillOpacity='0' x='0' y='0' width='26' height='26' />
          <path
            d='M19.9262749,4.00985336 L22.0475952,6.1311737 C22.8286438,6.91222229
           22.8286438,8.17855225 22.0475952,8.95960083 L9.88535858,21.1218375 L3.97911224,
           22.0038162 L4.93561111,16.17209
            L17.0978477,4.00985336 C17.8788963,3.22880478 19.1452263,3.22880478 19.9262749,
            4.00985336 Z M15.7091122,7.51906692
             L6.33768503,16.8913364 L5.79231699,20.2164183 L9.17452138,19.7113543 L18.5371122,
             10.3480669 L15.7091122,7.51906692
              Z M18.1585079,5.07051353 L16.7691122,6.45906692 L19.5971122,9.28706692 L20.986935,
              7.89894066 C21.1821972,7.70367851
               21.1821972,7.38709602 20.986935,7.19183388 L18.8656147,5.07051353 C18.6703526,
               4.87525139 18.3537701,4.87525139
                18.1585079,5.07051353 Z'
            id='Shape'
          />
        </g>
      </g>
    </g>
  </svg>
);
EditSvgIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
EditSvgIcon.defaultProps = {
  fill: '#000000',
  width: '26px',
  height: '26px',
};

const IconStyles = `
  font-family: missioncontrol !important;
  &:before {
    font-family: missioncontrol !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  },
  `;
export const Crops = styled.span`
  ${IconStyles} &:before {
    content: '\\e902';
    color: #10384f;
    font-size: 1.25rem;
  }
`;

export const Diseases = styled.span`
  ${IconStyles} &:before {
    content: '\\e903';
    color: #10384f;
    font-size: 1.25rem;
  }
`;

export const EppoMatch = styled.span`
  ${IconStyles} &:before {
    content: '\\e90b';
    color: #10384f;
    font-size: 1.25rem;
  }
`;

export const GrowthStages = styled.span`
  ${IconStyles} &:before {
    content: '\\e900';
    color: #10384f;
    font-size: 1.25rem;
  }
`;

export const GSRanges = styled.span`
  ${IconStyles} &:before {
    content: '\\e901';
    color: #10384f;
    font-size: 1.25rem;
  }
`;

export const Pests = styled.span`
  ${IconStyles} &:before {
    content: '\\e911';
    color: #10384f;
    font-size: 1.25rem;
  }
`;

export const UseCases = styled.span`
  ${IconStyles} &:before {
    content: '\\e90a';
    color: #10384f;
    font-size: 1.25rem;
  }
`;

export const Weeds = styled.span`
  ${IconStyles} &:before {
    content: '\\e904';
    color: #10384f;
    font-size: 1.25rem;
  }
`;
export const SearchIcon = styled.span`
  ${IconStyles} &:before {
    content: '\\e909';
    color: #8e8e93;
    font-size: 1rem;
  }
`;

export const ClearSearch = styled.span`
  ${IconStyles} &:before {
    content: '\\e907';
    color: #d8d8d8;
    font-size: 1rem;
  }
`;
export const User = styled.span`
  ${IconStyles} &:before {
    content: '\\e90c';
    color: #ffffff;
    font-size: 1rem;
  }
`;
export const DropDownIcon = styled.span`
  ${IconStyles} &:before {
    content: '\\e908';
    color: #656565;
    font-size: 0.75rem;
  }
`;

export const TickIcon = styled.span`
  ${IconStyles} &:before {
    content: '\\e905';
    color: #656565;
    font-size: 1rem;
  }
`;

export const CloseIcon = styled.span`
  ${IconStyles} &:before {
    content: '\\e90d';
    color: #10384f;
    font-size: 1rem;
    cursor: pointer;
  }
`;

export const AddIcon = styled.span`
  ${IconStyles} &:before {
    content: '\\e90f';
    color: #10384f;
    font-size: 1rem;
    cursor: pointer;
  }
`;

export const DeleteButton = styled.span`
  ${IconStyles} &:before {
    content: '\\e90e';
    color: #999;
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

const LokaliseIconContainer = styled.img`
  margin-left: 5px;
  margin-top: 3px;
  cursor: pointer;
  &.usecase {
    width: 18px;
    height: 14px;
  }
`;

export const LokaliseIcon = (props) => <LokaliseIconContainer src={lokalise} {...props} />;
export const CustomData = styled.span`
  ${IconStyles} &:before {
    content: '\\e912';
    color: #10384f;
    font-size: 1.25rem;
  }
`;
export const EditIcon = styled.span`
  ${IconStyles} &:before {
    content: '\\e913';
    color: #999;
    font-size: 1rem;
    cursor: pointer;
  }
`;

export const ResizeIcon = styled.span`
  ${IconStyles} &:before {
    content: '\\e914';
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
`;
export const BranchIcon = styled.span`
  ${IconStyles} &:before {
    content: '\\e915';
    color: #10384f;
    font-size: 1rem;
  }
`;

export const DragIcon = styled.span`
  ${IconStyles} &:before {
    content: '\\e916';
    color: #959595;
    font-size: 1rem;
  }
`;

import { defineMessages } from 'react-intl';

export const search = defineMessages({
  title: {
    id: 'text.search',
    defaultMessage: 'Search',
  },
});

export const subspeciesNotAvailable = defineMessages({
  title: {
    id: 'text.subspeciesnotavailable',
    defaultMessage: 'Sub species is not available.',
  },
});

export const cropsNotAvailable = defineMessages({
  title: {
    id: 'text.subspeciesnotavailable',
    defaultMessage: 'Sub species is not available.',
  },
});

export const primaryImage = defineMessages({
  title: {
    id: 'text.primaryimage',
    defaultMessage: 'PRIMARY IMAGE',
  },
});

export const additionalImages = defineMessages({
  title: {
    id: 'text.additionalimages',
    defaultMessage: 'ADDITIONAL IMAGES',
  },
});

export const addImage = defineMessages({
  title: {
    id: 'text.addimage',
    defaultMessage: 'Add Image',
  },
});

export const country = defineMessages({
  title: {
    id: 'text.country',
    defaultMessage: 'Country',
  },
});

export const scopes = defineMessages({
  title: {
    id: 'text.scopes',
    defaultMessage: 'Scopes',
  },
});

export const labelHigh = defineMessages({
  title: {
    id: 'text.high',
    defaultMessage: 'High',
  },
});
export const labelLow = defineMessages({
  title: {
    id: 'text.low',
    defaultMessage: 'Low',
  },
});
export const labelMedium = defineMessages({
  title: {
    id: 'text.medium',
    defaultMessage: 'Medium',
  },
});

export const labelConfig = defineMessages({
  title: {
    id: 'text.config',
    defaultMessage: 'Config',
  },
});

export const labelGeography = defineMessages({
  title: {
    id: 'text.geography',
    defaultMessage: 'Geography',
  },
});

export const configurationLabel = defineMessages({
  title: {
    id: 'text.configuration',
    defaultMessage: 'Configuration',
  },
});

export const usecaseImage = defineMessages({
  title: {
    id: 'text.usecaseimage',
    defaultMessage: 'Usecase Image',
  },
});

export const howToImages = defineMessages({
  title: {
    id: 'text.howToImages',
    defaultMessage: 'How To Images',
  },
});

export const stepslabel = defineMessages({
  title: {
    id: 'text.steps',
    defaultMessage: 'Steps',
  },
});

export const textModels = defineMessages({
  title: {
    id: 'text.models',
    defaultMessage: 'Models',
  },
});

export const deleteConfirmMessage = defineMessages({
  title: {
    id: 'dialog.deleteconfirm',
    defaultMessage: 'Are you sure that you want to delete',
  },
});

export const textSelected = defineMessages({
  title: {
    id: 'text.selected',
    defaultMessage: 'Selected',
  },
});

export const textAllitems = defineMessages({
  title: {
    id: 'text.allitems',
    defaultMessage: 'All Items',
  },
});

export const textName = defineMessages({
  title: {
    id: 'text.name',
    defaultMessage: 'Name',
  },
});

export const textSteps = defineMessages({
  title: {
    id: 'text.steps',
    defaultMessage: 'Steps',
  },
});

export const textFilter = defineMessages({
  title: {
    id: 'text.filter',
    defaultMessage: 'Filter',
  },
});

export const textAddStep = defineMessages({
  title: {
    id: 'text.addstep',
    defaultMessage: 'Add Step ',
  },
});

export const textPleaseWait = defineMessages({
  title: {
    id: 'text.pleasewait',
    defaultMessage: 'Please wait',
  },
});

export const textCustomforms = defineMessages({
  title: {
    id: 'text.customforms',
    defaultMessage: 'Custom Forms',
  },
});

export const removeDependencyConfirmMessage = defineMessages({
  title: {
    id: 'dialog.removedependencyconfirm',
    defaultMessage: 'Do you want to remove the dependency filter?',
  },
});

export const toggleDistributionConfirmMessage = defineMessages({
  title: {
    id: 'dialog.toggledistributionconfirm',
    defaultMessage: 'Do you want to change the distribution?',
  },
});

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _UserSettings from './UserSettings';

const UserSettings = withRouter(_UserSettings);

class UserSettingContainer extends Component {
  state = {
    languages: [
      { key: 'de', name: 'German' },
      { key: 'en', name: 'English' },
    ],
  };

  render() {
    return <UserSettings languages={this.state.languages} {...this.props} />;
  }
}

export default UserSettingContainer;

import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import App from './App';
import './assets/css/styles';
import MCProvider from './MCProvider';
import * as serviceWorker from './serviceWorker';

WebFont.load({
  google: {
    families: ['Lato'],
  },
});

ReactDOM.render(
  <MCProvider>
    <App />
  </MCProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

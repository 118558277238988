import { ReactComponent as aimapping } from './aimapping.svg';
import { ReactComponent as aimodel } from './AIModel.svg';
import { ReactComponent as Crops } from './Crop.svg';
import { ReactComponent as Custom } from './Custom.svg';
import { ReactComponent as Deploy } from './Deploy.svg';
import { ReactComponent as Diseases } from './Diseases.svg';
import { ReactComponent as EPPO } from './EPPO.svg';
import { ReactComponent as msconfig } from './msconfig.svg';
import { ReactComponent as Pests } from './Pests.svg';
import { ReactComponent as TaskManagement } from './TaskManagement.svg';
import { ReactComponent as UseCases } from './UseCases.svg';
import { ReactComponent as Weeds } from './Weed.svg';

export default {
  aimodel,
  aimapping,
  Crops,
  Custom,
  Deploy,
  Diseases,
  Weeds,
  UseCases,
  TaskManagement,
  Pests,
  EPPO,
  msconfig,
};

import { Button } from '@material-ui/core';
import { Component } from 'react';

/* eslint-disable */
export default class MCErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.log(error.stack);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'grid',
            gridAutoFlow: 'row',
            gridAutoRows: 'min-content',
            justifyItems: 'center',
          }}
        >
          <h1>Something went wrong.</h1>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Home
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

import { gql } from '@apollo/client';
import React from 'react';
import { QueryClient, QueryClientProvider, useMutation, useQueries, useQuery } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import ApolloClient from './apollo/apollo.client';
import MCUtils from './MCUtils';

const client = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (e) => MCUtils.notify(e.message || ''),
      retry: false,
    },
    queries: {
      onError: (e) => MCUtils.notify(e.message || ''),
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      staleTime: 1000 * 60,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage });

persistQueryClient({
  queryClient: client,
  persistor: localStoragePersistor,
});

export default function ReactQueryProvider({ children }) {
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
}

const apolloQueryFunc = async (query, variables) => {
  const resp = await ApolloClient.query({
    query: gql(query),
    fetchPolicy: 'no-cache',
    variables,
  });
  if (resp.errors) {
    throw new Error(resp.errors[0].message);
  }
  return resp.data;
};

const defaultSelectFunc = (d) => d[Object.keys(d)[0]];
/**
 * @typedef {Object} QueryParam
 * @property {string} key
 * @property {"fcai"|"aimm"|"msc"} apiName
 * @property {string} query
 * @property {Object} variables
 * @property {import('react-query').UseQueryOptions} queryOptions
 */

/**
 * @param {QueryParam} opt
 * @returns {import('react-query').UseQueryResult} response
 */
export function usePrivateApiQuery({ key, apiName, query, variables, queryOptions }) {
  return useQuery(
    key,
    () =>
      MCUtils.callPrivateApiThrow({
        apiName,
        query,
        variables,
      }),
    { select: defaultSelectFunc, ...queryOptions },
  );
}

/**
 * @typedef {Object} MutateParam
 * @property {"fcai"|"aimm"|"msc"} apiName
 * @property {string} query
 * @property {string | String[]} invlidateKey
 * @property {import('react-query').UseMutationOptions} queryOptions
 */

/**
 *
 * @param {MutateParam} mutateParam
 * @returns {import('react-query').UseMutationResult} result
 */
export function usePrivateApiMutation({ apiName, query, queryOptions, invlidateKey }) {
  return useMutation(
    (variables) =>
      MCUtils.callPrivateApiThrow({
        apiName,
        query,
        variables,
      }),
    {
      onSuccess: () => {
        if (invlidateKey) client.invalidateQueries(invlidateKey);
      },
      ...queryOptions,
    },
  );
}

/**
 * @typedef {Object} ApolloQueryParam
 * @property {string | unknown[]} key
 * @property {string} query
 * @property {Object} variables
 * @property {import('react-query').UseQueryOptions} queryOptions
 */

/**
 * @param {ApolloQueryParam} opt
 * @returns {import('react-query').UseQueryResult} response
 */
export function useApolloQuery({ key, query, variables, queryOptions }) {
  return useQuery({
    select: defaultSelectFunc,
    ...queryOptions,
    queryKey: key,
    queryFn: () => apolloQueryFunc(query, variables),
  });
}

/**
 * @param {ApolloQueryParam[]} opt
 * @returns {import('react-query').UseQueryResult[]} response
 */
export function useApolloQueries(opt) {
  return useQueries(
    opt.map((o) => ({
      select: defaultSelectFunc,
      ...o.queryOptions,
      queryKey: o.key,
      queryFn: () => apolloQueryFunc(o.query, o.variables),
    })),
  );
}

/**
 * @typedef {Object} ApolloMutateParam
 * @property {string} query
 * @property {string} invlidateKey
 * @property {import('react-query').UseMutationOptions} queryOptions
 */

/**
 *
 * @param {ApolloMutateParam} mutateParam
 * @returns {import('react-query').UseMutationResult} result
 */
export function useApolloMutation({ query, queryOptions, invlidateKey }) {
  return useMutation(
    async (variables) => {
      const resp = await ApolloClient.mutate({
        mutation: gql(query),
        variables,
        fetchPolicy: 'no-cache',
      });
      if (resp.errors) {
        throw new Error(resp.errors[0].message);
      }
      return resp.data;
    },
    {
      onSuccess: () => {
        if (invlidateKey) client.invalidateQueries(invlidateKey);
      },
      ...queryOptions,
    },
  );
}

export const invalidateAllQueries = () => client.getQueryCache().clear();
export const invalidateQueries = (key, exact = false) => {
  client.invalidateQueries(key, {
    exact,
    refetchInactive: true,
    refetchActive: true,
  });
};

/**
 * @param {ApolloQueryParam} opt
 */
export const reactQueryQuery = ({ key, query, variables, queryOptions }) =>
  client.fetchQuery({
    key,
    queryFn: () => apolloQueryFunc(query, variables),
    ...queryOptions,
  });

import { gql } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { getCategoriesWithScope } from '../graphql/local.operations';
import {
  getCustomAttributesByCustomDataId,
  getCustomAttributeValuesByItemId,
  getCustomDataById,
  listUsecaseByCountry,
} from '../graphql/queries';
import ApolloClient from './apollo.client';

class InputField {
  constructor({ value, touched, valid, error, params } = {}) {
    this.value = value || '';
    this.touched = touched || false;
    this.valid = valid === undefined ? true : valid;
    this.error = error || '';
    this.errorParams = params ? { ...params } : {};
  }
}

const resolvers = {
  CustomData: {
    customAttributes() {
      return {
        useGPS: false,
        fields: [
          {
            id: uuid(),
            title: new InputField({ value: '' }),
            annotationKey: new InputField({
              value: '',
            }),
            fieldType: new InputField({ value: '' }),
            options: [],
          },
        ],
      };
    },
  },
  Mutation: {
    UpdateCustomAttribute: (_root, variables, { cache }) => {
      const customData = cache.readQuery({
        query: gql(getCustomDataById),
        variables: {
          id: variables.id,
          languageCode: 'en',
        },
      }).getCustomDataByID;

      cache.writeQuery({
        query: gql(getCustomDataById),
        variables: {
          id: variables.id,
          languageCode: 'en',
        },
        data: {
          getCustomDataByID: {
            ...customData,
            customAttributes: variables.customAttributes,
          },
        },
      });

      const customAttributes = {
        ...variables.customAttributes,
        fields: variables.customAttributes.fields.map((field) => {
          let fieldObj = {
            type: '',
            title: '',
            options: [],
            field: new InputField({ value: '' }),
          };
          switch (field.fieldType.value.toLowerCase()) {
            case 'checkbox':
            case 'select':
              fieldObj = {
                ...fieldObj,
                id: field.id,
                title: field.title.value,
                type: field.fieldType.value.toLowerCase(),
                options: field.options.map((option) => ({
                  id: option.id,
                  option: option.option.value,
                  checked: option.checked,
                })),
              };
              break;
            case 'input':
              fieldObj = {
                ...fieldObj,
                id: field.id,
                title: field.title.value,
                type: field.fieldType.value.toLowerCase(),
                options: [],
              };
              break;

            default:
              break;
          }
          return fieldObj;
        }),
      };
      cache.writeQuery({
        query: gql(getCustomAttributesByCustomDataId),
        variables: {
          id: variables.id,
        },
        data: { getCustomAttributesByCustomDataId: customAttributes },
      });

      return null;
    },

    UpdateCustomAttributesValue: (_root, variables, { cache }) => {
      const existingData = cache.readQuery({
        query: gql(getCustomAttributeValuesByItemId),
        variables: {
          customDataId: variables.customDataId,
          customDataItemId: variables.customDataItemId,
        },
      });

      cache.writeQuery({
        query: gql(getCustomAttributeValuesByItemId),
        variables: {
          customDataId: variables.customDataId,
          customDataItemId: variables.customDataItemId,
        },
        data: {
          getCustomAttributeValuesByItemId: {
            ...existingData,
            fields: variables.customAttributeValues,
          },
        },
      });

      return null;
    },
    UpadateCategoriesWithScope: (_root, variables, { cache }) => {
      cache.writeQuery({
        query: gql(getCategoriesWithScope),
        variables: {
          scopeId: variables.scopeId,
          usecaseId: variables.usecaseId,
        },
        data: {
          getCategoriesWithScope: variables.data,
        },
      });

      return null;
    },
    UpdateUsecaseLokaliseDataInLocal: (_root, variables, { cache }) => {
      let listUsecase = cache.readQuery({
        query: gql(listUsecaseByCountry),
        variables: {
          countryId: variables.countryId,
        },
      }).listUsecaseByCountry;
      listUsecase = listUsecase.map((usecase) => {
        if (usecase.id === variables.usecaseId) {
          return {
            ...usecase,
            lokaliseData: variables.lokaliseData,
            isIncomplete: false,
          };
        }
        return usecase;
      });
      cache.writeQuery({
        query: gql(listUsecaseByCountry),
        variables: {
          countryId: variables.countryId,
        },
        data: {
          listUsecaseByCountry: listUsecase,
        },
      });
      return null;
    },
  },
  Query: {
    getCustomAttributeValuesByItemId: (_root, variables, { cache }) => {
      const customData = cache.readQuery({
        query: gql(getCustomAttributesByCustomDataId),
        variables: {
          id: variables.customDataId,
        },
      }).getCustomAttributesByCustomDataId;

      cache.writeQuery({
        query: gql(getCustomAttributeValuesByItemId),
        variables: {
          customDataId: variables.customDataId,
          customDataItemId: variables.customDataItemId,
        },
        data: {
          getCustomAttributeValuesByItemId: customData,
        },
      });

      return customData;
    },
    getLokaliseDataByUsecaseId: (_root, variables) =>
      ApolloClient.query({
        query: gql(listUsecaseByCountry),
        variables: {
          countryId: variables.countryId,
        },
        fetchPolicy: 'network-only',
      }).then((resp) => {
        const listUsecase = resp.data.listUsecaseByCountry;
        return listUsecase.find((uc) => uc.id === variables.usecaseId);
      }),
  },
};

export default resolvers;

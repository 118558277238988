// update mui to v5
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

const mainColor = '#0091df';
const missionControlTheme = createMuiTheme({
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: '#FF2020',
    },
    text: {
      main: '#959595',
      light: '#656565',
    },
  },
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: '#5BC6FF',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#E4F6FF !important',
        },
      },
    },
    MuiListItemText: {
      primary: {
        color: '#656565',
      },
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        color: '#959595',
      },
      asterisk: {
        color: 'red',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '14px',
      },
    },
    MuiInputBase: {
      inputAdornedStart: {
        paddingLeft: '0.5rem',
      },
    },
    MuiInput: {
      root: { color: '#656565', minHeight: '40px' },
      underline: {
        borderBottom: '1px solid rgba(151,151,151,0.5)',
        '&:before': {
          display: 'none !important',
        },
        '&::after': {
          borderBottomWidth: '1px',
        },
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: '#E4F6FF !important',
        },
      },
      hover: {
        cursor: 'pointer',
        '&:hover': { backgroundColor: '#E4F6FF !important' },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
      textColorInherit: {
        color: mainColor,
        opacity: 1,
      },
    },
    MuiFormControlLabel: {
      label: {
        textTransform: 'uppercase',
        color: '#959595',
        fontSize: '1rem',
      },
    },
    MuiTableCell: {
      body: {
        color: '#10384F',
      },
      head: {
        color: '#959595',
        fontSize: '1rem',
        textTransform: 'capitalize',
      },
      sizeSmall: {
        '&:last-child': {
          paddingRight: '0.25rem',
        },
      },
    },
    MuiChip: {
      label: {
        color: '#656565',
      },
    },
  },
});

export default missionControlTheme;

export const updateCustomAttribute = `mutation UpdateCustomAttribute(
    $id: String!
    $customAttributes: CustomAttributes! 
    ) {
      UpdateCustomAttribute(id: $id, customAttributes:  $customAttributes) @client 
  }
  `;

export const UpdateCustomAttributesValue = `mutation UpdateCustomAttributesValue(
    $customDataId: String!
    $customDataItemId: String!
    $customAttributeValues: customAttributeValues! 
    ) {
      UpdateCustomAttributesValue(customDataId: $customDataId, 
        customDataItemId: $customDataItemId,
        customAttributeValues:  $customAttributeValues) @client 
  }
  `;

export const getCategoriesWithScope = `query GetCategoriesWithScope($scopeId: String!, $usecaseId: String!) 
@client {
    getCategoriesWithScope(scopeId: $scopeId, usecaseId: $usecaseId) {
      CategoriesWithScope
    }
  }
  `;

export const getLokaliseDataByUsecaseId = `query GetLokaliseDataByUsecaseId($countryId: String!, 
    $usecaseId: String!) {
      getLokaliseDataByUsecaseId(countryId: $countryId, 
        usecaseId: $usecaseId) @client(always: true)  
  }
  `;

export const UpdateUsecaseLokaliseDataInLocal = `mutation UpdateUsecaseLokaliseDataInLocal(
    $usecaseId: String!
    $countryId: String!
    $lokaliseData: lokaliseData! 
    ) {
      UpdateUsecaseLokaliseDataInLocal(
        usecaseId: $usecaseId, 
        countryId: $countryId,
        lokaliseData:  $lokaliseData) @client 
  }
  `;

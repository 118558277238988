/* eslint-disable import/prefer-default-export */
import { withStyles } from '@material-ui/core/styles';
import _Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import _Button from '@material-ui/core/Button';
import _Input from '@material-ui/core/Input';
import _Select from '@material-ui/core/Select';
import _MenuItem from '@material-ui/core/MenuItem';
import _FormControl from '@material-ui/core/FormControl';

export const Grid = withStyles({
  item: {
    height: 'calc(100vh - 80px)',
    overflow: 'hidden',
    overflowY: 'auto',
  },
})(_Grid);

export const UserSettingsWrapper = styled.div`
  margin: 20px auto auto 18%;
  min-width: 620px;
  font-family: 'Lato';
  width: 48%;
`;
export const Heading = styled.div`
  color: #5a5a5a;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.22px;
  line-height: 42px;
  margin-bottom: 20px;
  margin-top: 60px;
`;
export const FieldLabel = styled.div`
  width: 100%;
  color: #959595;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const FieldValue = styled.div`
  width: 100%;
  color: #9a9a9a;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  line-height: 22px;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 10px;
  &.select: {
    border-bottom-width: 0px;
  }
`;

export const Button = withStyles({
  root: {
    fontFamily: 'Lato, Arial',
    color: '#FF2020',
    borderRadius: '50px',
    textTransform: 'none',
    padding: '0px 35px 0px 35px',
    lineHeight: '38px',
    minWidth: '165px',
    boxShadow: 'none',
  },
  contained: {
    color: '#FFF',
    backgroundColor: '#0091DF',
    float: 'right',
    marginLeft: '15px',
    '&:hover': {
      background: '#087FBF',
    },
  },
  outlined: {
    border: '1px solid #0091DF',
    borderRadius: '20px',
    color: '#0091DF',
    fontWeight: '400',
    letterSpacing: '0.16px',
    float: 'right',
    height: '40px',
  },
})(_Button);

export const Input = withStyles({
  root: {
    width: '100%',
    fontFamily: 'Lato, Arial',
    color: '#656565',
  },
  underline: {
    borderBottom: '1px solid rgba(151,151,151,0.5)',
    '&:after': {
      borderBottom: '1px solid #0091DF',
      bottom: '-1px',
    },
    '&:before': {
      display: 'none !important',
    },
  },
})(_Input);

export const Select = withStyles({
  root: {
    fontFamily: 'Lato, Arial',
    width: '100%',
    paddingBottom: '5px',
    background: 'transparent',
  },
})(_Select);

export const MenuItem = withStyles({
  root: {
    color: '#656565',
    fontSize: '16px',
    letterSpacing: '0.16px',
    lineHeight: '22px',
  },
})(_MenuItem);

export const FormControl = withStyles({
  root: {
    width: '100%',
    margin: '10px 4% 10px 0px',
  },
})(_FormControl);

import { gql } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { validate } from 'uuid';
import ApolloClient from './apollo/apollo.client';
import {
  executePrivateApi,
  fetchTranslations,
  getAllMasterData,
  getCropDepItems,
  listAllStressTypes,
  listEppoMatchCountries,
  listMasterImages,
  listScopes,
} from './graphql/queries';
import { labelHigh, labelLow, labelMedium } from './messages';
import { useApolloQuery } from './ReactQueryProvider';

export default class MCUtils {
  /**
   *
   * @param {String} data
   * @returns
   */
  static isValidJson(data) {
    try {
      JSON.parse(data);
      return true;
    } catch (e) {
      return false;
    }
  }

  static createMultiDimentionalImages = (src, originalImage, dimensionsArray) => {
    let img;
    const promises = [];
    dimensionsArray.forEach((dimension) => {
      const isOriginal = Object.keys(dimension)[0] === 'original';
      const { width } = dimension[Object.keys(dimension)[0]];
      const { height } = dimension[Object.keys(dimension)[0]];
      const srcImage = isOriginal ? originalImage : src;
      img = new Image();
      promises.push(
        new Promise((resolve, reject) => {
          if (isOriginal) {
            const blobData = MCUtils.dataURItoBlob(originalImage);
            resolve({ [Object.keys(dimension)[0]]: blobData });
          } else {
            img.onload = () => {
              const oc = document.createElement('canvas');
              const octx = oc.getContext('2d');
              oc.width = width;
              oc.height = height;
              octx.drawImage(img, 0, 0, oc.width, oc.height);
              const dataUrl = oc.toDataURL('image/jpeg');
              const blobData = MCUtils.dataURItoBlob(dataUrl);
              resolve({ [Object.keys(dimension)[0]]: blobData });
            };
            img.onError = () => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('Error something went wrong');
            };
          }
        }),
      );
      img.src = srcImage;
    });
    return Promise.all(promises);
  };

  static dataURItoBlob = (dataURI) => {
    try {
      let binary = '';
      if (dataURI.split(',')[0].indexOf('base64') >= 0) binary = atob(dataURI.split(',')[1]);
      else binary = unescape(dataURI.split(',')[1]);
      const array = [];
      binary.split('').map((bin) => {
        array.push(bin.charCodeAt(0));
        return bin;
      });
      return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      return null;
    }
  };

  static base64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      if (!data.ok) reject(new Error(data.statusText));
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  static checkFileSize = (file) => {
    const URL = window.URL || window.webkitURL;
    const img = new Image();
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        resolve(img);
      };
      img.onError = () => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Error something went wrong');
      };
      img.src = URL.createObjectURL(file);
    });

    return promise;
  };

  static notify = (msg) => {
    toast.error(msg.replace('GraphQL error:', '').replace('pq:', '').trim(), { icon: false });
  };

  static callMultipleTimes = (numberOfIteration) => (func) => {
    if (numberOfIteration > 0) {
      func();
      MCUtils.callMultipleTimes(numberOfIteration - 1)(func);
    }
  };

  static parseJson = (data) => {
    try {
      return JSON.parse(data);
    } catch (err) {
      return false;
    }
  };

  static ConfigGeographyTabs = [
    {
      labelId: 'text.config',
      key: 1,
      value: 1,
    },
    {
      labelId: 'text.geography',
      key: 2,
      value: 2,
    },
  ];

  static ConfigUseCaseTabs = [
    {
      labelId: 'text.steps',
      key: 1,
      value: 1,
    },
    {
      labelId: 'text.countries',
      key: 2,
      value: 2,
    },
    {
      labelId: 'text.customforms',
      key: 3,
      value: 3,
    },
    // {
    //   labelId: 'text.models',
    //   key: 4,
    //   value: 4,
    // },
  ];

  static AllAndSelectedTabs = [
    {
      labelId: 'text.selected',
      key: 1,
      value: 1,
    },
    {
      labelId: 'text.allitems',
      key: 2,
      value: 2,
    },
  ];

  static ColorCodeImportance = {
    0: '#7EC3E8',
    1: '#0091DF',
    2: '#005C8E',
  };

  static Importance = {
    0: {
      id: labelLow.title.id,
      defaultMessage: labelLow.title.defaultMessage,
    },
    1: {
      id: labelMedium.title.id,
      defaultMessage: labelMedium.title.defaultMessage,
    },
    2: {
      id: labelHigh.title.id,
      defaultMessage: labelHigh.title.defaultMessage,
    },
  };

  static aiModelCompare(a, b) {
    const first = parseInt(a.class.value, 10);
    const second = parseInt(b.class.value, 10);
    if (first > second) return 1;
    if (first < second) return -1;
    return 0;
  }

  static async callPrivateApi({ apiName, query, variables, fetchPolicy }) {
    try {
      const resp = await ApolloClient.query({
        query: gql(executePrivateApi),
        variables: {
          name: apiName,
          apiQuery: query,
          variables: JSON.stringify(variables),
        },
        fetchPolicy: fetchPolicy || 'cache-first',
      });
      if (resp.errors) {
        this.notify(resp.errors[0].message);
      }
      const privateApiResponse = JSON.parse(resp.data.executePrivateApi.jsonString);
      if (privateApiResponse.errors) {
        this.notify(privateApiResponse.errors[0].message);
      }
      return privateApiResponse.data || {};
    } catch (e) {
      this.notify(e.message);
    }
    return {};
  }

  static async callPrivateApiThrow({ apiName, query, variables }) {
    const resp = await ApolloClient.query({
      query: gql(executePrivateApi),
      variables: {
        name: apiName,
        apiQuery: query,
        variables: JSON.stringify(variables),
      },
      fetchPolicy: 'no-cache',
    });
    if (resp.errors) {
      throw new Error(resp.errors[0].message);
    }
    if (!resp.data.executePrivateApi.jsonString) {
      throw new Error('api request failed with no response');
    }
    const privateApiResponse = JSON.parse(resp.data.executePrivateApi.jsonString);
    if (privateApiResponse.errors) {
      throw new Error(privateApiResponse.errors[0].message);
    }
    return privateApiResponse.data;
  }

  /**
   *
   * @param {String} url
   * @param {Blob} imgBlob
   * @returns
   */
  static async uploadImgToS3(url, imgBlob) {
    const response = await fetch(url, {
      method: 'PUT',
      body: imgBlob,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    });
    return response;
  }

  /**
   *
   * @param {String} url
   * @param {Blob} imgBlob
   * @returns
   */
  static useUploadImgToS3(url, imgBlob) {
    return useQuery({
      queryKey: 'uploads3image',
      enabled: false,
      cacheTime: 0,
      queryFn: () => MCUtils.uploadImgToS3(url, imgBlob),
    });
  }

  /**
   *
   * @param {number?} len defaults to 6
   * @returns hex color string
   */
  static randomColor(len = 6) {
    const str = Math.floor(Math.random() * 16 ** len).toString(16);
    return `#${'0'.repeat(len - str.length) + str}`;
  }
}

/**
 *
 * @param {any} val
 * @param {number} [timeout] time to wait in ms default 200
 * @returns
 */
export const useDebounce = (val, timeout = 200) => {
  const [value, setValue] = useState(val);
  useEffect(() => {
    const t = setTimeout(() => {
      setValue(val);
    }, timeout);
    return () => clearTimeout(t);
  }, [val, timeout]);
  return value;
};

export const useEppoCountryList = () =>
  useApolloQuery({
    key: 'listeppocountries',
    query: listEppoMatchCountries,
  });

export function useGetAllMasterData(scopeId = null) {
  return useApolloQuery({
    key: ['masterdata', scopeId],
    query: getAllMasterData,
    variables: { scopeId },
    queryOptions: {
      select: (data) =>
        data.fetchAllMasterData.sort((a, b) => {
          if (a.scopeName === b.scopeName) {
            return a.title.localeCompare(b.title);
          }
          return a.scopeName.localeCompare(b.scopeName);
        }),
    },
  });
}

/**
 *
 * @param {import('react-hook-form').UseFormRegister} register useForm register function
 * @returns {(name:string)=>import('react-hook-form').UseFormRegisterReturn & {inputRef:import('react-hook-form').RefCallBack}}
 */
export function useMuiRegister(register) {
  return useCallback(
    (name) => {
      const ret = register(name);
      return { ...ret, inputRef: ret.ref };
    },
    [register],
  );
}

export const useListScopes = () =>
  useApolloQuery({
    key: 'listscopes',
    query: listScopes,
    queryOptions: {
      select: (data) => data.listScopes.sort((a, b) => a.name.localeCompare(b.name)),
    },
  });

export const useAllStressTypes = (isStressTypes) =>
  useApolloQuery({
    key: 'listallstresstypes',
    query: listAllStressTypes,
    queryOptions: {
      enabled: isStressTypes,
    },
  });

export const useGetCropDep = (cropIds = [], scopeName = '', enabled = false) =>
  useApolloQuery({
    key: ['listcropdep', scopeName],
    query: getCropDepItems,
    variables: { cropIds: [], scopeName },
    queryOptions: {
      enabled,
      select: (d) => {
        const dataArray = d.listCropDependentItems.filter((c) => cropIds.includes(c.id));
        const respArray = [];
        if (scopeName === 'DISEASE') dataArray.forEach((cropIt) => respArray.push(...(cropIt.diseases ? cropIt.diseases : [])));
        else dataArray.forEach((cropIt) => respArray.push(...(cropIt.pests ? cropIt.pests : [])));
        return [...new Set(respArray)];
      },
    },
  });

export const useListMasterImages = () =>
  useApolloQuery({
    key: 'listmasterimages',
    query: listMasterImages,
    queryOptions: {
      select: (data) => {
        const { fetchMasterImageList } = data;
        if (fetchMasterImageList) {
          return fetchMasterImageList.map((f) => ({ ...f, imageList: JSON.parse(f.imageList) }));
        }
        return fetchMasterImageList;
      },
    },
  });

export const useListTranslations = ({ countryId, languageId }) =>
  useApolloQuery({
    key: ['listtranslations', countryId, languageId],
    query: fetchTranslations,
    variables: { countryId, languageId },
    queryOptions: {
      enabled: validate(countryId) && validate(languageId),
    },
  });

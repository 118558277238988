import { withApollo } from '@apollo/client/react/hoc';
import React, { useEffect } from 'react';
import Dashboard from './components/Dashboard/Dashboard';
import { useAuthContext } from './MCProvider';

function setFavicon() {
  if (import.meta.env.VITE_MIGRATE_ENV !== 'prod') {
    const links = document.getElementsByClassName('favicon_main');
    for (let i = 0; i < links.length; i += 1) {
      links.item(i).href = `/favicon_${import.meta.env.VITE_MIGRATE_ENV}.png`;
    }
  }
}

function App() {
  useEffect(() => {
    setFavicon();
  }, []);
  const ctx = useAuthContext();
  return <Dashboard username={ctx.email} email={ctx.email} />;
}

export default withApollo(App);

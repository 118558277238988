export const listWeeds = `query ListWeeds {
  listWeeds {
    id
    eppoCode    
    isGenus
    name
    thumbnailImage
    globalCommonName
  }
}
`;
export const getWeedById = `query GetWeedById($id: String!) {
  getWeedById(id: $id) {
    id
    eppoCode
    codeId
    stressType
    isGenus
    isDisabled
    annotationKey
    imageData
    name
    isEppo
    parentCode
    parentId
    thumbnailImage
    globalCommonName
    imageCategories{
      id
      name:categoryName
      items:images{
        id:imageId
        displayOrder
      }
    }
  }
}
`;
export const getWeedByEppo = `query GetWeedByEppo($eppoCode: String!) {
  getWeedByEppo(eppoCode: $eppoCode) {
    id
    eppoCode
    codeId
    stressType
    isGenus
    isDisabled
    annotationKey
    imageData
    name
    isEppo
    parentCode
    parentId
    thumbnailImage
    globalCommonName
  }
}
`;
export const listStressTypes = `query ListStressTypes($scope: String!, $languageCode: String!) {
  listStressTypes(scope: $scope, languageCode: $languageCode) {
    id
    name
    annotationValue
    scope
  }
}
`;
export const getStressTypeById = `query GetStressTypeById($id: String!, $languageCode: String!) {
  getStressTypeById(id: $id, languageCode: $languageCode) {
    id
    name
    annotationValue
    scope
  }
}
`;
export const listCodeTypes = `query ListCodeTypes($scope: String!) {
  listCodeTypes(scope: $scope) {
    id
    name
  }
}
`;
export const getCodeImagePresign = `query GetCodeImagePresign($id: String) {
  getCodeImagePresign(id: $id) {
    id
    url
    url1x
    url1_5x
    url2x
    url3x
    url4x
    urlHd
    urlHdr
  }
}
`;
export const listCrops = `query ListCrops {
  listCrops {
    id
    codeId
    eppoCode
    isGenus
    name
    thumbnailImage
    globalCommonName
  }
}
`;
export const getCropById = `query GetCropById($id: String!) {
  getCropById(id: $id) {
    id
    eppoCode
    codeId
    cropType
    isGenus
    isDisabled
    annotationKey
    name
    cropCharacteristics
    imageData
    isEppo
    parentCode
    parentId
    thumbnailImage
    globalCommonName
  }
}
`;
export const getCropByEppo = `query GetCropByEppo($eppoCode: String!) {
  getCropByEppo(eppoCode: $eppoCode) {
    id
    eppoCode
    codeId
    cropType
    isGenus
    isDisabled
    annotationKey
    name
    cropCharacteristics
    imageData
    isEppo
    parentCode
    parentId
    thumbnailImage
    globalCommonName
  }
}
`;
export const listCropTypes = `query ListCropTypes($languageCode: String!) {
  listCropTypes(languageCode: $languageCode) {
    id
    name
    annotationValue
  }
}
`;
export const listCropCharacteristics = `query ListCropCharacteristics {
  listCropCharacteristics {
    id
    name
  }
}
`;
export const listPests = `query ListPests {
  listPests {
    id
    eppoCode
    isGenus
    name
    thumbnailImage
    globalCommonName
  }
}
`;
export const getPestById = `query GetPestById($id: String!) {
  getPestById(id: $id) {
    id
    eppoCode
    codeId
    stressType
    isGenus
    isDisabled
    annotationKey
    name
    damageTypes
    imageData
    isEppo
    parentCode
    parentId
    thumbnailImage
    globalCommonName
  }
}
`;
export const getPestByEppo = `query GetPestByEppo($eppoCode: String!) {
  getPestByEppo(eppoCode: $eppoCode) {
    id
    eppoCode
    codeId
    stressType
    isGenus
    isDisabled
    annotationKey
    name
    damageTypes
    imageData
    isEppo
    parentCode
    parentId
    thumbnailImage
    globalCommonName
  }
}
`;
export const listDamageTypes = `query ListDamageTypes($languageCode: String!, $scope: String!) {
  listDamageTypes(languageCode: $languageCode, scope: $scope) {
    id
    name
  }
}
`;
export const listDiseases = `query ListDiseases {
  listDiseases {
    id
    eppoCode
    name
    thumbnailImage
    globalCommonName
  }
}
`;
export const getDiseaseById = `query GetDiseaseById($id: String!) {
  getDiseaseById(id: $id) {
    id
    eppoCode
    codeId
    stressType
    annotationKey
    name
    damageTypes
    imageData
    isEppo
    thumbnailImage
    globalCommonName
  }
}
`;
export const getDiseaseByEppo = `query GetDiseaseByEppo($eppoCode: String!) {
  getDiseaseByEppo(eppoCode: $eppoCode) {
    id
    eppoCode
    codeId
    stressType
    annotationKey
    name
    damageTypes
    imageData
    isEppo
    thumbnailImage
    globalCommonName
  }
}
`;
export const listUseCaseImages = `query ListUseCaseImages {
  listUseCaseImages {
    imageData
  }
}
`;
export const getUseCaseImagePresign = `query GetUseCaseImagePresign($id: String, $isPrimary: Boolean!) {
  getUseCaseImagePresign(id: $id, isPrimary: $isPrimary) {
    id
    url
    url1x
    url1_5x
    url2x
    url3x
    url4x
    urlHd
    urlHdr
  }
}
`;
export const listWeedSubSpeciesById = `query ListWeedSubSpeciesById($weedId: String!) {
  listWeedSubSpeciesById(weedId: $weedId) {
    id
    code
    englishName
    latinName
    isSelected
  }
}
`;
export const listWeedSubSpecies = `query ListWeedSubSpecies($codeTypeId: String!) {
  listWeedSubSpecies(codeTypeId: $codeTypeId) {
    id
    code
    englishName
    latinName
    isSelected
  }
}
`;
export const listCropSubSpecies = `query ListCropSubSpecies($codeTypeId: String!) {
  listCropSubSpecies(codeTypeId: $codeTypeId) {
    id
    code
    englishName
    latinName
    isSelected
  }
}
`;
export const listCropSubSpeciesById = `query ListCropSubSpeciesById($cropId: String!) {
  listCropSubSpeciesById(cropId: $cropId) {
    id
    code
    englishName
    latinName
    isSelected
  }
}
`;
export const listEppoMatchCountries = `query ListEppoMatchCountries {
  listEppoMatchCountries {
    id
    countryName
    countryISO2Code
    totalDisplayCount
    primaryLanguageID
    languages{
      id
      englishName
      iso2Code
    }
  }
}
`;

export const listCountryScopes = `query ListCountryScopes($countryId: String!) {
  listCountryScopes(countryId: $countryId) {
    id
    name
    count
    isUsecase
    isCustomData
  }
}
`;
export const listCodesByCountryScope = `query ListCodesByCountryScope($countryId: String!, $scopeId: String!) {
  listCodesByCountryScope(countryId: $countryId, scopeId: $scopeId) {
    codeId
    code
    latinName
    importanceLevel
    isSelected
    isIncomplete
    commonName
    isCustomItem
  }
}
`;
export const listLokaliseContent = `query ListLokaliseContent(
  $countryId: String!
  $scopeId: String!
  $codeId: String!
) {
  listLokaliseContent(
    countryId: $countryId
    scopeId: $scopeId
    codeId: $codeId
  ) {
    importanceLevel
    content
    globalNames
    latinName
    isEppo
    deployable
  }
}
`;
export const listLanguagesByCountryId = `query ListLanguagesByCountryId($countryId: String!) {
  listLanguagesByCountryId(countryId: $countryId) {
    id
    iso2Code
    isPrimary
    englishName
  }
}
`;
export const listUsecases = `query ListUsecases($languageCode: String!) {
  listUsecases(languageCode: $languageCode) {
    id
    label
  }
}
`;
export const getUsecaseByIdCountryMatch = `query GetUsecaseById(
  $id: String!
  $countryCode: String
  $languageCode: String!
) {
  getUsecaseById(
    id: $id
    countryCode: $countryCode
    languageCode: $languageCode
  ) {
    id
    label
    description        
  }
}
`;
export const getUsecaseById = `query GetUsecaseById($id: String!, $languageCode: String!) {
  getUsecaseById(id: $id, languageCode: $languageCode) {
    id
    label
    description
    annotationKey
    multiImageSupport
    commentsSupport
    primaryImage
    steps {
      id
      scopeId
      displayName
      displayOrder
      filters {
        id
        categoryName
        isDefault
        dependencyScope
        customDependency
        displayCount
        displayOrder
        entityIds
        dependentEntities {
          parentId
          entityIds
        }
      }
    }
    helpImages {
      imageId
      howTo
      displayOrder
    }
    customFormData {
      isMandatory
      showByDefault
      customFormJson
    }
  }
}
`;
export const listWeedEppoMapCountries = `query ListWeedEppoMapCountries($weedId: String!) {
  listWeedEppoMapCountries(weedId: $weedId) {
    id
    countryName
    countryISO2Code
    importanceLevel
    isDeployable
    primaryLanguageCode
    primaryLanguageTitle
  }
}
`;
export const listUseCaseCountries = `query ListUseCaseCountries($usecaseId: String) {
  listUseCaseCountries(usecaseId: $usecaseId) {
    id
    countryName
    countryISO2Code
    isSelected
  }
}
`;
export const listScopes = `query ListScopes {
  listScopes {
    id
    name
    count
    isUsecase
    isCustomData
  }
}
`;
export const listUsecaseFilterCategories = `query ListUsecaseFilterCategories($scopeId: String!) {
  listUsecaseFilterCategories(scopeId: $scopeId) {
    id
    categoryName
    isDefault
    dependencyScope
    customDependency
    displayCount
    displayOrder
  }
}
`;
export const listUsecaseFilterEntities = `query ListUsecaseFilterEntities(
  $usecaseId: String
  $filterCategoryId: String!
  $entityId: String
) {
  listUsecaseFilterEntities(
    usecaseId: $usecaseId
    filterCategoryId: $filterCategoryId
    entityId: $entityId
  ) {
    id
    label
    description
    columnCount
    isSelected
  }
}
`;
export const listUsecaseFilterDependentEntities = `query ListUsecaseFilterDependentEntities(
  $stepFilterEntities: [InputFilterModel]!
  $usecaseId: String
  $filterCategoryId: String!
) {
  listUsecaseFilterDependentEntities(
    stepFilterEntities: $stepFilterEntities
    usecaseId: $usecaseId
    filterCategoryId: $filterCategoryId
  ) {
    id
    label
    displayCount
  }
}
`;
export const importEppoLocalNames = `query ImportEppoLocalNames($countryId: String!, $codeId: String!) {
  importEppoLocalNames(countryId: $countryId, codeId: $codeId) {
    languageCode
    localNames
  }
}
`;

export const listPestSubSpecies = `query ListPestSubSpecies($codeTypeId: String!) {
  listPestSubSpecies(codeTypeId: $codeTypeId) {
    id
    code
    englishName
    latinName
    isSelected
  }
}
`;
export const listPestSubSpeciesById = `query ListPestSubSpeciesById($pestId: String!) {
  listPestSubSpeciesById(pestId: $pestId) {
    id
    code
    englishName
    latinName
    isSelected
  }
}
`;
export const generateUsecaseConfigPresign = `query GenerateUsecaseConfigPresign($id: String!) {
  generateUsecaseConfigPresign(id: $id) {
    id
    url
  }
}
`;

export const listCropEppoMapCountries = `query ListCropEppoMapCountries($cropId: String!) {
  listCropEppoMapCountries(cropId: $cropId) {
    id
    countryName
    countryISO2Code
    importanceLevel
    isDeployable
    primaryLanguageCode
    primaryLanguageTitle
  }
}
`;
export const listDiseaseEppoMapCountries = `query ListDiseaseEppoMapCountries($diseaseId: String!) {
  listDiseaseEppoMapCountries(diseaseId: $diseaseId) {
    id
    countryName
    countryISO2Code
    importanceLevel
    isDeployable
    primaryLanguageCode
    primaryLanguageTitle
  }
}
`;
export const listPestEppoMapCountries = `query ListPestEppoMapCountries($pestId: String!) {
  listPestEppoMapCountries(pestId: $pestId) {
    id
    countryName
    countryISO2Code
    importanceLevel
    isDeployable
    primaryLanguageCode
    primaryLanguageTitle
  }
}
`;
export const getCodeImageCount = `query GetCodeImageCount($codeId: String!, $annotationKey: String!) {
  getCodeImageCount(codeId: $codeId, annotationKey: $annotationKey) {
    totalCount
    countryCount {
      countryCode
      count
    }
  }
}
`;

export const listDiseaseCrops = `query ListDiseaseCrops {
  listDiseaseCrops {
    id
    code
    englishName
    latinName
    parentId
    isSelected
    globalCommonName
  }
}
`;
export const listDiseaseCropsById = `query ListDiseaseCropsById($diseaseId: String!) {
  listDiseaseCropsById(diseaseId: $diseaseId) {
    id
    code
    englishName
    latinName
    parentId
    isSelected
    globalCommonName
  }
}
`;
export const listCustomData = `query ListCustomData($languageCode: String!) {
  listCustomData(languageCode: $languageCode) {
    id
    title
  }
}
`;
export const getCustomDataById = `query GetCustomDataById($id: String!, $languageCode: String!) {
  getCustomDataByID(id: $id, languageCode: $languageCode) {
    id
    scopeId
    title
    description
    customAttributes @client
  }
}
`;

export const getCustomAttributesByCustomDataId = `query GetCustomAttributesByCustomDataId($id: String!) {
  getCustomAttributesByCustomDataId(id: $id) @client(always: true)  
}
`;

export const getCustomAttributeValuesByItemId = `query GetCustomAttributeValuesByItemId($customDataId: String!, 
  $customDataItemId: String!) {
  getCustomAttributeValuesByItemId(customDataId: $customDataId, 
    customDataItemId: $customDataItemId) @client(always: true)  
}
`;

export const listCustomDataItems = `query ListCustomDataItems($customDataId: String!) {
  listCustomDataItems(customDataId: $customDataId) {
    id
    title
    annotationKey
    imageData
    thumbnailImage
  }
}
`;
export const getCustomDataItemById = `query GetCustomDataItemById($customItemId: String!) {
  getCustomDataItemByID(customItemId: $customItemId) {
    id
    title
    annotationKey
    imageData
    thumbnailImage
  }
}
`;

export const getCustomItemImagePresign = `query GetCustomItemImagePresign($id: String) {
  getCustomItemImagePresign(id: $id) {
    id
    url
    url1x
    url1_5x
    url2x
    url3x
    url4x
  }
}
`;
export const listPestCrops = `query ListPestCrops {
  listPestCrops {
    id
    code
    englishName
    latinName
    parentId
    isSelected
    globalCommonName
  }
}
`;
export const listPestCropsById = `query ListPestCropsById($pestId: String!) {
  listPestCropsById(pestId: $pestId) {
    id
    code
    englishName
    latinName
    parentId
    isSelected
    globalCommonName
  }
}
`;
export const listAiConfigUsecases = `query ListAiConfigUsecases {
  listAIConfigUsecases {
    id
    name
    displayCount
  }
}
`;
export const listUsecaseAnnotations = `query ListUsecaseAnnotations($usecaseId: String!) {
  listUsecaseAnnotations(usecaseId: $usecaseId) {
    id
    annotationKey
  }
}
`;
export const listVisualizationTypes = `query ListVisualizationTypes {
  listVisualizationTypes {
    id
    visualizationType
  }
}
`;
export const listModelTypesByUsecase = `query ListModelTypesByUsecase($usecaseId: String!) {
  listModelTypesByUsecase(usecaseId: $usecaseId) {
    id
    name
    entityId
    isUsecase
    annotationKey
    isGlobal
    regionData {
      id
      name
      countries {
        id
        countryCode
      }
      classes {
        id
        class
        classOutput
        inProd
        displayOrder
      }
      apiUrl
      fileUrl
      visualizationType
      displayOrder
    }
  }
}
`;
export const getModelTypeById = `query GetModelTypeById($modelTypeId: String!) {
  getModelTypeByID(modelTypeId: $modelTypeId) {
    id
    name
    entityId
    isUsecase
    annotationKey
    isGlobal
    regionData {
      id
      name
      countries {
        id
        countryCode
      }
      classes {
        id
        class
        classOutput
        classCommonName
        searchQuery
        inProd
        displayOrder
      }
      apiUrl
      fileUrl
      fileSize
      fileVersion
      confidenceLevel
      visualizationType
      modelTypeId
      modelInputTypeId
      interpolationMethodId
      standardization
      standardizationMean
      standardizationStd
      normalization
      normalizationFactor
      displayOrder
    }
  }
}
`;
export const listDevicePlatforms = `query ListDevicePlatforms {
  listDevicePlatforms {
    id
    name
    onlyUrl
  }
}
`;
export const listMasterCountries = `query ListMasterCountries {
  listMasterCountries {
    id
    countryName
    countryISO2Code
    importanceLevel
  }
}
`;
export const listUsecaseByCountry = `query ListUsecaseByCountry($countryId: String!) {
  listUsecaseByCountry(countryId: $countryId) {
    id
    title
    description
    isIncomplete
    lokaliseData {
      languageID
      languageCode
      title
      description
    }
  }
}
`;
export const listModelType = `query ListModelType {
  listModelType {
    id
    key
    displayName
    isDefault
  }
}
`;
export const listModelInputType = `query ListModelInputType {
  listModelInputType {
    id
    key
    displayName
    isDefault
  }
}
`;
export const listInterpolationMethod = `query ListInterpolationMethod {
  listInterpolationMethod {
    id
    key
    displayName
    isDefault
  }
}
`;
export const getDbGenProgress = `
{
  getDbGenerationStatus{
      id,
      userId,
      overallStatus,
      lastDeployed
  }
}
`;
export const filterUseCaseByCountry = `
query filterUseCaseByCountry($countryCode:String!,$langCode:String){
  listUsecases(countryCode:$countryCode,filterOption:{countryCode:$countryCode},languageCode:$langCode){
    id
    label
    primaryImage
    description
  }
}`;

export const listStepEntityForTasks = `query list($det: StepEntityIP!){
  listUsecaseStepEntities(stepEntityInput:$det){
    id
    title
    subTitle
    annotationKey
    image
  }
}`;

export const listTasks = `query listTasks{
  listTask{
      id
      taskNo
      startDate
      dueDate
      daysLeft
      status
      userCount
      countryName
      countryISO2Code
      totalTargetCount
      usecaseName
      parentEntityName
      targetCount
      createdBy
  }
}`;

export const getTaskId = `query getTaskByID($id:String!){
  getTaskByID(id:$id){
      id
      taskNo
      countryId
      startDate
      dueDate
      status
      usecaseId
      createdBy
      targetScopeId
      comment
      taskTarget
      stepMapping{
          scopeId
          entityId
          entityTitle
      }
      targets{
          entityId
          targetCount
          entityTitle
          entitySubTitle
          displayTarget
      }
      users{
        id
        targets{
          targetId:id
          count
        }
      }
    }
}`;

export const queryCognitoUsers = `query listCognitoUserDetails{
  listCognitoUserDetails{
    id
    email
  }
}`;

export const listAllScopes = `{
  listAllScopes{
    id
    name
    isUsecase
    isCustomData
  }
}`;

export const listCountryMatchStep = `query listCountryMatchStep($opt:CountryStepEntityIP!){
  listCountryMatchStepEntities(countryStepEntityInput:$opt){
    id title subTitle isMatched isCustom image
  }
}`;

export const getAllMasterData = `query GetAllMasterData($scopeId:String){
  fetchAllMasterData(scopeId: $scopeId) {
    id
    annotationKey
    scopeId
    scopeName
    subTitle
    title
    ... on MasterDataUtilItem {
      eppoCode
      isEppo
      stressType
      latinName
    }
  }
}
`;
export const executePrivateApi = `query ExecutePrivateApi(
  $name: String!
  $apiQuery: String!
  $variables: String
) {
  executePrivateApi(apiName: $name, query: $apiQuery, variables: $variables) {
    jsonString
  }
}
`;
export const listAllStressTypes = `query ListAllStressTypes{
  listAllStressTypes(languageCode:"en"){
    id
    name,
    scope
  }
}`;

export const getCropDepItems = `query ListCropDependentItems($cropIds:[String]!,$scopeName:CropDepType!){
  listCropDependentItems(in:{cropCodeIds:$cropIds,scopeName:$scopeName}){
    id
    pests
    diseases
  }
}`;

export const listAllIcons = `query ListIcons {
  listIcons {
    id
    fileName
    deletedAt
  }
}`;

export const listImageCategories = `query ListImageCategories{
  listImageCategories{
    id
    name
    code
    scopeId
    scopeName
    order
  }
}`;

export const listFcDeployHistory = `query GetDbGenHistory{
  getDbGenHistory {
    countryCode
    languageCode
    version
    status
    date
    overallStatus
  }
}
`;

export const getCustomItemCountryMatch = ` query GetCustomItemCountryMatchData($id:String!){
  getCustomItemCountryMatchData(customItemId:$id) {
    country
    importance:importanceLevel
    isChecked:isMapped
    localName:content
  }
}
`;

export const listPrincipalGrowthStages = `query ListPrincipalGwStages{
  listPrincipalGrowthStages {
    id
    name
    shortDescription
    longDescription
    growthStages {
      id
      name
      shortDescription
      longDescription
    }
  }
}`;

export const getGrowthStageGroupByid = `query GetGrowthStageGroupById($id: String!) {
  getGrowthStageGroupById(id: $id) {
    id
    name
    entities
    stressType
    type
    principalGrowthStages {
      id
      shortDescription
      longDescription
      growthStages {
        id
        shortDescription
        longDescription
      }
    }
  }
}
`;

export const listAllGrowthStageGroups = `query ListGrowthStageGroup {
  listGrowthStageGroups {
    id
    name
    type
  }
}
`;

export const listMasterImages = `query FetchMasterImageList {
  fetchMasterImageList {
    entityId
    imageList
  }
}
`;

export const fetchTranslations = `query FetchAllTranslations($countryId: String!, $languageId: String!) {
  fetchAllTranslations(countryId: $countryId,languageId: $languageId) {
    content
    countryCode
    languageCode
    entityId
  }
}
`;

export const getTaskImageCount = `query GetTaskImageCount($taskIds: [String]!, $userId: String) {
  fetchTaskImageCountNew(taskIds: $taskIds, userId: $userId) {
    taskId:task_id
    userId:user_id
    entityId:entity_id
    count
  }
}`;

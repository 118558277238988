import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as DevIcon } from '../../../assets/img/DEV.svg';
import { ReactComponent as QaIcon } from '../../../assets/img/QA.svg';
import { routeMessages } from '../../../routes';
import { HeaderContainer, Page, Pipe, Title, TitleBold, User, UserIcon } from './Header.styles';

const Header = ({ primary, username }) => {
  const { pathname } = useLocation();
  const page = pathname.split('/')[1];
  const intl = useIntl();
  return (
    <HeaderContainer style={{ position: 'relative' }} primary={primary}>
      {import.meta.env.VITE_MIGRATE_ENV !== 'prod' && (
        <div
          style={{
            position: 'absolute',
            left: '9rem',
            top: '3.5rem',
          }}
        >
          {import.meta.env.VITE_MIGRATE_ENV === 'qa' ? <QaIcon /> : <DevIcon />}
        </div>
      )}
      <Title data-cy='title'>
        Mission
        <TitleBold>Control</TitleBold>
      </Title>
      {page && page !== 'login' && (
        <span>
          {' '}
          <Pipe />
          <Page data-cy='page-header'>{intl.formatMessage(routeMessages[page])}</Page>
        </span>
      )}

      {username ? (
        <Link to='/settings' data-cy='header-user'>
          <User>
            <UserIcon /> {username}
          </User>
        </Link>
      ) : (
        ''
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  username: PropTypes.string,
  primary: PropTypes.bool,
};

Header.defaultProps = {
  username: '',
  primary: false,
};

export default Header;

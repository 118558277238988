import React from 'react';

export default () => (
  <div className='spinner-container'>
    <div className='lds-ripple'>
      <div />
      <div />
    </div>
  </div>
);

import styled from 'styled-components';
import * as Icons from '../../../Icons';
import 'react-toastify/dist/ReactToastify.css';

const CloseIcon = styled(Icons.CloseIcon)`
  top: 50%;
  top: calc(50% - 12px);
  right: 20px;
  position: absolute;
  z-index: 1000;
  &::before {
    font-size: 14px;
    color: white;
  }
`;
export default CloseIcon;

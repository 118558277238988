import React from 'react';
import styled, { css } from 'styled-components';
import * as Icons from '../../../Icons';

export const HeaderContainer = styled.div`
  height: 80px;
  width: 100%;
  ${(props) =>
    props.primary &&
    css`
      background-color: #0091df;
      background: #0091df;
    `};
`;

export const User = styled.div`
  float: right;
  height: 19px;
  color: #ffffff;
  font-family: Lato, Arial;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  padding: 20px 40px;
  cursor: pointer;
`;

export const Title = styled.span`
  padding: 18px;
  display: inline-block;
  color: #ffffff;
  font-family: Lato;
  font-size: 1.5em;
  letter-spacing: 0.21px;
  line-height: 31px;
`;

export const TitleBold = styled.span`
  font-weight: bold;
`;

export const Page = styled.span`
  height: 36px;
  padding: 18px;
  width: 67px;
  color: #ffffff;
  font-family: Lato, Arial;
  font-size: 1.5em;
  letter-spacing: 0.27px;
  line-height: 36px;
`;

export const Pipe = styled.span`
  display: inline-block;
  top: 15px;
  position: relative;
  height: 50px;
  width: 1px;
  background: #ffffff;
`;

const CustomUser = styled(Icons.User)`
  margin-right: 10px;
`;
export const UserIcon = (props) => <CustomUser {...props} />;

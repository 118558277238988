import { Auth } from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  FieldLabel,
  FieldValue,
  FormControl,
  Grid,
  Heading,
  Input,
  MenuItem,
  Select,
  UserSettingsWrapper,
} from './UserSettings.styles';

class UserSettings extends Component {
  state = {
    language: 'en',
  };

  componentDidMount() {
    try {
      if (localStorage.getItem('mission-control-language')) {
        this.setState({ language: localStorage.getItem('mission-control-language') });
      } else {
        /* eslint-disable-next-line prefer-destructuring */
        const language = navigator.language.split(/[-_]/)[0]; // language without region code
        this.setState({ language });
      }
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.log('Unable to read browser language - using English');
    }
  }

  handleChange = (event) => {
    this.setState({
      language: event.target.value,
    });
  };

  signOut = () => {
    Auth.signOut({ global: true })
      .then(() => window.location.reload())
      .catch(() => {});
  };

  saveSettings = () => {
    localStorage.setItem('mission-control-language', this.state.language);
    window.location.reload();
  };

  render() {
    const { languages } = this.props;
    return (
      <Grid item xs={12} className='pt-1 pb-1'>
        <UserSettingsWrapper>
          <Heading>
            <FormattedMessage id='settings.headingpersonaldata' defaultMessage='Personal Data' />
          </Heading>
          {/* <FieldLabel>
              <FormattedMessage
                id="settings.fullname"
                defaultMessage="Full Name"
              />
            </FieldLabel>
            <FieldValue>{this.props.fullName}</FieldValue> */}
          <FieldLabel>
            <FormattedMessage id='settings.email' defaultMessage='E-Mail' />
          </FieldLabel>
          <FieldValue>{this.props.email}</FieldValue>
          <Heading>
            <FormattedMessage id='settings.headingaccountsettings' defaultMessage='Account Settings' />
          </Heading>
          <FieldLabel>
            <FormattedMessage id='settings.language' defaultMessage='Language' />
          </FieldLabel>
          <FormControl>
            <Select value={this.state.language} onChange={this.handleChange} input={<Input id='language' name='language' />}>
              {languages.map((lang) => (
                <MenuItem key={lang.key} value={lang.key}>
                  {lang.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className='pt-1 pb-1 w-100 d-ib'>
            <Button variant='contained' component='span' onClick={this.saveSettings}>
              <FormattedMessage id='action.save' defaultMessage='Save' />
            </Button>
            <Button variant='outlined' onClick={this.signOut} data-cy='sign-out-btn'>
              <FormattedMessage id='settings.signout' defaultMessage='Sign Out' />
            </Button>
          </div>
        </UserSettingsWrapper>
      </Grid>
    );
  }
}

UserSettings.propTypes = {
  // fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  languages: PropTypes.instanceOf(Array).isRequired,
};
export default UserSettings;

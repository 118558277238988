import { ApolloProvider } from '@apollo/client';
import Auth from '@aws-amplify/auth';
import { ThemeProvider } from '@material-ui/core';
import React, { createContext, StrictMode, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import de from './assets/translations/de.json';
import en from './assets/translations/en.json';
import Spinner from './components/Shared/Spinner/Spinner';
import { MCGroups } from './routes';
import ApolloClient from './utils/apollo/apollo.client';
import MCErrorBoundary from './utils/MCErrorBoundary';
import ReactQueryProvider from './utils/ReactQueryProvider';
import missionControlTheme from './utils/theme';

const AuthContext = createContext({ email: '', groups: [], isAdmin: false, userId: '' });
export const useAuthContext = () => useContext(AuthContext);
const language = localStorage.getItem('mission-control-language') || 'en';
const messages = language === 'en' ? en : de;

export default function MCProvider({ children }) {
  const [state, setState] = useState({ isLoading: true });
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups = Array.from(user.signInUserSession.accessToken.payload['cognito:groups']);
        setState({
          isLoading: false,
          username: user.attributes.email,
          email: user.attributes.email,
          ctxValue: {
            userId: user.attributes.sub,
            email: user.attributes.email,
            groups,
            isAdmin: groups.includes(MCGroups.Admin),
          },
        });
      })
      .catch(() => {
        window.location.href = `${import.meta.env.VITE_LOGIN_URL}?productName=MissionControl&redirectUrl=${
          window.location.origin
        }`;
      });
  }, []);
  return state.isLoading ? (
    <Spinner />
  ) : (
    <StrictMode>
      <MCErrorBoundary>
        <IntlProvider locale={language} messages={messages}>
          <ApolloProvider client={ApolloClient}>
            <AuthContext.Provider value={state.ctxValue}>
              <BrowserRouter>
                <ThemeProvider theme={missionControlTheme}>
                  <ReactQueryProvider>{children}</ReactQueryProvider>
                </ThemeProvider>
              </BrowserRouter>
            </AuthContext.Provider>
          </ApolloProvider>
        </IntlProvider>
      </MCErrorBoundary>
    </StrictMode>
  );
}
